@import '@/assets/css/index.scss';

.bannerWithoutImageWrapper {
    display: flex;
    flex-direction: column;
    padding: $content-padding--large $content-padding;
    background-color: var(--color-white);
    gap: $content-padding--medium;
}

.bannerContentWrapper {
    width: 100%;
    padding: 60px $content-padding--large 0 $content-padding--large;
}

.bannerImageOverlay {
    z-index: 1;
    height: 100%;
    background-color: var(--color-catalinaBlue-04);
}

.bannerContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: $content-padding--medium;
}

.textWrapper {
    z-index: 1;
    row-gap: $content-padding--medium;
}

.title {
    color: var(--color-white);
    font-size: 24px;
    font-weight: bold;
}

.subtitle {
    color: var(--color-white);
    font-size: $font-size-medium;
    word-break: break-word;
}

.text {
    font-size: $font-size-extra-small;
}
